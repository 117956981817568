
.vuedl-layout {
  position: relative;
}
.vuedl-layout__closeBtn {
  position: absolute;
  top: 16px;
  width: 16px;
  height: 16px;
  font-family: -webkit-pictograph;
  font-size: 30px;
  opacity: 0.5;
  z-index: 1000;
  cursor: pointer;
  line-height: 0.5;
}
.v-application--is-ltr .vuedl-layout__closeBtn {
  right: 14px;
}
.v-application--is-rtl .vuedl-layout__closeBtn {
  left: 14px;
}
.vuedl-layout__closeBtn:hover {
  opacity: 1;
}

.v-dialog:not(.v-dialog--fullscreen) .v-card.v-inner-scroll .v-card__text {
  max-height: 70vh;
}
.v-card.v-inner-scroll .v-card__text {
  overflow: auto;
  background: linear-gradient(white 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0) 10px, white 70%) bottom, radial-gradient(at top, rgba(0, 0, 0, 0.2), transparent 70%), radial-gradient(at bottom, rgba(0, 0, 0, 0.2), transparent 70%) bottom;
  background-repeat: no-repeat;
  background-size: 100% 30px, 100% 30px, 100% 10px, 100% 10px;
  background-attachment: local, local, scroll, scroll;
}
.theme--dark.v-inner-scroll .v-card__text {
  background: linear-gradient(#1d1d1d 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0) 10px, #1d1d1d 70%) bottom, radial-gradient(at top, rgba(255, 255, 255, 0.2), transparent 70%), radial-gradient(at bottom, rgba(255, 253, 253, 0.2), transparent 70%) bottom;
  background-repeat: no-repeat;
  background-size: 100% 30px, 100% 30px, 100% 10px, 100% 10px;
  background-attachment: local, local, scroll, scroll;
}

/*# sourceMappingURL=DialogCard.vue.map */

.vuedl-notification {
  display: flex;
  box-sizing: border-box;
  position: fixed;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
  transition: opacity .3s, transform .3s, left .3s, right .3s, top .4s, bottom .3s;
  overflow: hidden;
}
.vuedl-notification>div:first-child {
  width: 100%;
}
.vuedl-notification.right {
  right: 16px;
}
.vuedl-notification.left {
  left: 16px;
}
.vuedl-notification__closeBtn {
  position: absolute;
  top: 9px;
  right: 15px;
  cursor: pointer;
  color: #909399;
  font-size: 22px;
}
.vuedl-notification__closeBtn:hover {
  color:#606266;
}
.vuedl-notification-fade-enter.right{
  right: 0;
  transform: translateX(100%);
}
.vuedl-notification-fade-enter.left{
  left: 0;
  transform: translateX(-100%);
}
.vuedl-notification-fade-leave-active {
  opacity: 0;
}
@media screen and (max-width: 450px) {
.vuedl-notification {
    left: 8px !important;
    right: 8px !important;
    max-width: inherit !important;
}
}






























/* Absolute Center Spinner */
.dialog-overlay-loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  cursor: wait;
}

/* Transparent Overlay */
.dialog-overlay-loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(112, 112, 112, 0.4), rgba(50, 50, 50, .8));
}

/* :not(:required) hides these rules from IE9 and below */
.dialog-overlay-loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.dialog-overlay-loading:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  animation: spinner 1500ms infinite linear;
  border-radius: 0.5em;
  box-shadow: rgba(255,255,255, 0.75) 1.5em 0 0 0, rgba(255,255,255, 0.75) 1.1em 1.1em 0 0, rgba(255,255,255, 0.75) 0 1.5em 0 0, rgba(255,255,255, 0.75) -1.1em 1.1em 0 0, rgba(255,255,255, 0.75) -1.5em 0 0 0, rgba(255,255,255, 0.75) -1.1em -1.1em 0 0, rgba(255,255,255, 0.75) 0 -1.5em 0 0, rgba(255,255,255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */
@-webkit-keyframes spinner {
0% {
    transform: rotate(0deg);
}
100% {
    transform: rotate(360deg);
}
}
@-moz-keyframes spinner {
0% {
    transform: rotate(0deg);
}
100% {
    transform: rotate(360deg);
}
}
@-o-keyframes spinner {
0% {
    transform: rotate(0deg);
}
100% {
    transform: rotate(360deg);
}
}
@keyframes spinner {
0% {
    transform: rotate(0deg);
}
100% {
    transform: rotate(360deg);
}
}
